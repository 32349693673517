import React from "react"
import MainLayout from "@/layouts/MainLayout"
import Terms from "@/components/Terms"

export default () => {
  return (
    <MainLayout>
      <Terms />
    </MainLayout>
  )
}